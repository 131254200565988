import React from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

// Components
import ButtonShell from 'components/elements/Buttons/ButtonShell'

// Images
import EmergencyExit from 'img/emergency-exit.inline.svg'
import ArrowBold from 'img/arrow-bold.inline.svg'

export const ButtonAlternateCSS = css`
  background-color: ${({ theme }) => theme.color.primary};
  display: inline-flex;
  align-items: center;
  border: 2px solid ${(props) => props.theme.color.primary};
  transition: all 0.3s ease-in-out;

  & > a,
  & > button {
    color: ${(props) => props.theme.color.light} !important;
    font-size: 18px !important;
    font-family: ${({ theme }) => theme.font.family.primary};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    padding: 5px 20px 5px 30px;
    height: 100%;
    display: inline-flex;
    align-items: center;
    text-decoration: none !important;
  }

  &:hover {
    background-color: #00955c;
    border: 2px solid ${(props) => props.theme.color.light};
  }
`

const StyledButton = styled.span`
  ${ButtonAlternateCSS};
`

interface ButtonAlternateProps {
  isAnchor?: boolean
  isCustom?: boolean
  to: string
  className?: string
  target?: string
}

const ButtonAlternate: React.FC<ButtonAlternateProps> = ({
  isAnchor = false,
  isCustom = false,
  to,
  children,
  className = '',
  target = '',
}) => (
  <StyledButton className={className}>
    {isCustom ? (
      children
    ) : (
      <ButtonShell to={to} isAnchor={isAnchor} target={target}>
        {children}
        <EmergencyExit className="ms-4" />
        <motion.div
          animate={{ x: [5, -5] }}
          transition={{ yoyo: Infinity }}
          className="d-inline-flex"
        >
          <ArrowBold className="ms-3" />
        </motion.div>
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonAlternate
